<template>
<v-app>
  <v-container>
    <h4 class="mt-5">
      If you have an account, an email has been sent to reset your password. You are redirected to the login page in 5 seconds. 
    </h4>
  </v-container>
</v-app>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
          this.$router.push('/login');
    }, 5*1000);
  },
}
</script>
